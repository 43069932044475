import React from 'react'
import ApplyContent from './apply-content'
import Layout from '../../components/layout'
const Apply = props => {
  return (
    <Layout type='agent' location={props.location}>
      <ApplyContent />
    </Layout>
  )
}

export default Apply
